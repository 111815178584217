import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import { UserManagementReducer } from './UserManagementReducer';
import { RoleManagementReducer } from './RoleManagementReducer';
import { UserRoleReducer } from './UserRoleReducer';
import { AdminReducer } from './AdminReducer';
import { CostManagementReducer } from './CostManagementReducer';
import Chat from './Chat';
import { BusinessDirectoryReducer } from './BusinessDirectoryReducer';
import { CategoryManagementReducer } from './CategoryManagementReducer';
import { JobReducer } from './JobReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    UserManagementReducer,
    RoleManagementReducer,
    UserRoleReducer,
    AdminReducer,
    CostManagementReducer,
    Chat,
    BusinessDirectoryReducer,
    CategoryManagementReducer,
    JobReducer,
  });
