import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardExpendableContent from '../../../../../@coremat/CmtCardExpendableContent';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';
//import Quotes from './Quotes';
import moment from 'moment';
import JobDetailsModal from "./JobDetailsModal";
import CloseJobModal from "./CloseJobModal";
import JobStatusModal from "./jobStatusModal";
import RequestQuoteModal from "./requestQuoteModal";
import { http } from "../../../../../appmakers/services/http-service";
import { rootUrl } from "../../../../../api-handling/rootUrl";
import { useHistory } from "react-router";
import { getOffSet } from "../../../../../helpers/dateHelper"

const useStyles = makeStyles(theme => ({
    subTitle: { display: 'flex', flexDirection: 'row' },
    status: { marginLeft: '4vw' },
}));

const ApmExpendableCard = ({ job, getJobs }) => {
    const history = useHistory();
    const classes = useStyles();
    const utcOffset = getOffSet();

    const [modal, setModal] = useState('');
    const { catid } = useParams();

    const getActions = (status) => {
        if (status == 'CLOSED') {
            var lable2 = 'Reopen job';
        } else {
            var lable2 = 'Close job';
        }
        const actionss = [
            {
                label: 'Job details',
                job: job,
            },
            {
                label: lable2,
                jobId: job._id,
            },
            job.quoteLimit == job.noOfQuotes && { label: 'Request for More Quote' },
        ];
        return actionss;
    }
    const subTitle = (date, status, quoteLimit, noOfQuotes) => {
        return (
            <div>
                <div className={classes.subTitle}>
                    <Typography color="textSecondary" component="span">
                        Posted on: {moment(date).utcOffset(utcOffset).format('MMM DD, YYYY')}
                    </Typography>

                    <Typography className={classes.status}>
                        Status: {job.quoteLimit == job.noOfQuotes && <strong>Quote Limit Reached</strong>}
                        {status === 'PENDING' && job.quoteLimit != job.noOfQuotes && <strong>Open for quoting</strong>}
                        {status === 'CLOSED' && job.quoteLimit != job.noOfQuotes && <strong>Closed</strong>}
                        {status === 'I need more quotes' && job.quoteLimit != job.noOfQuotes && <strong>I need more quotes</strong>}
                        {status === "I've found someone to do the job" && job.quoteLimit != job.noOfQuotes && <strong>I've found someone to do the job</strong>}
                        {status === "I want to close this job" && job.quoteLimit != job.noOfQuotes && <strong>I want to close this job</strong>}
                    </Typography>

                </div>
                <div>

                    <Typography color="textSecondary" >
                        No of Quotes: <strong>{noOfQuotes}/{quoteLimit}</strong>
                    </Typography>
                </div>
            </div>
        );
    };

    const modalHandler = (item) => {
        debugger
        setModal(item.label)
        //console.log(item)
        if (item.label == 'Reopen job') {
            http.patch(`${rootUrl}/update-job-status/${item.jobId}`, {
                status: "PENDING",
                reason: ""
            }).then(res => {
                //history.push(`/view-leads-details/${catid}`);
                getJobs();
            }).catch(error => {
                console.log('error', error);
            })
        }
        if (item.label == 'Job details') {
            history.push({
                pathname: '/jobs-details',
                state: item // your data array of objects
            })

        }

    };
    const closeModal = () => {
        setModal('');
        getJobs();
    };

    let jobName = '';
    job.questionAnswers.find(item => {
        if (item.pushedQuestion === 'Category') {
            jobName = item.answer_radio
        }
    })
    return (
        <CmtCard>
            <CmtCardHeader
                title={<h4 className="font-bold">{jobName}</h4>}
                subTitle={subTitle(job.createdAt, job.status, job.quoteLimit, job.usersQuoted.length)}
                actions={getActions(job.status)}
                actionHandler={modalHandler}
                actionHandleIcon={<MoreVertIcon fontSize="default" />}>
                <Button color="primary" onClick={() => { setModal('job satus') }}>Update job status</Button>
            </CmtCardHeader>
            {/* <CmtCardExpendableContent>*/}
            {/* <Quotes quotes={job.usersQuoted} jobId={job._id} postedBy={job.postUserId}></Quotes> */}
            {/* </CmtCardExpendableContent>  */}
            {modal === 'Job details' &&
                <JobDetailsModal open={modal === 'Job details'} closeModal={closeModal} item={job} category={jobName} />
            }
            <CloseJobModal open={modal === 'Close job'} getJobs={getJobs} closeModal={closeModal} jobId={job._id} />
            <JobStatusModal open={modal === 'job satus'} setModal={setModal} closeModal={closeModal} jobId={job._id} />
            <RequestQuoteModal open={modal === 'Request for More Quote'} quoteLimit={job.quoteLimit} setModal={setModal} closeModal={closeModal} jobId={job._id} />
        </CmtCard>
    );
};

export default ApmExpendableCard;
