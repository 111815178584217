import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Home } from './Pages/Authenticated/Home';
import { UserRoles } from './Pages/Authenticated/UserRoles/index';
import { CostManagement } from './Pages/Authenticated/CostManagement/index';
import { CategoryManagement } from './Pages/Authenticated/CategoryManagement/index';
import { UpdateCategory } from './Pages/Authenticated/CategoryManagement/UpdateCategory_new';

import { UserDetails } from './Pages/Authenticated/Home/UserDeatailsNew';
import { Dashboard } from './Pages/Authenticated/Dashboard';
import Settings from './Pages/Authenticated/settings';

import { ViewAllLeads } from './Pages/Authenticated/Dashboard/viewallleads';
import { ViewAllLeadsByLocation } from './Pages/Authenticated/Dashboard/viewallleads-locations';
import BulkUpload from './Pages/Authenticated/BulkUpload';
import Error404 from './Pages/404/index';
import Login from './Auth/Login';
import ForgotPasswordPage from './Auth/ForgotPassword';
import CreateResetPassword from './Auth/ResetPassword';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import MyJobs from './Pages/Authenticated/myJobs';
import JobDetails from './Pages/Authenticated/myJobs/components/JobDetails';

import { ActivityLogs } from './Pages/Authenticated/ActivityLogs';
import { Complain } from './Pages/Authenticated/Complain';

import { getMenu } from '../redux/actions/RoleManagement';
import BusinessPublicProfile from './Pages/Authenticated/BusinessPublicProfile';
import { UserSearch } from './Pages/Authenticated/UserSearch';
import { LeadSearch } from './Pages/Authenticated/LeadSearch';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
// const clearCacheData = () => {
//   caches.keys().then((names) => {
//     names.forEach((name) => {
//       caches.delete(name);
//     });
//   });
//   //alert("Complete Cache Cleared");
// };
const Routes = () => {
  const dispatch = useDispatch();
  const { menu } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  // useEffect(() => {
  //   clearCacheData()
  // }, [])
  useEffect(() => {
    if (!menu || !menu.length) dispatch(getMenu());
  }, [menu]);

  const permissionsRoutes =
    menu &&
    menu.map(item => {
      switch (item.link) {
        case '/dashboard':
          return <RestrictedRoute path="/dashboard" component={Dashboard} />;
        case '/bulk-upload':
          return <RestrictedRoute path="/bulk-upload" component={BulkUpload} />;
        case '/user-roles':
          return <RestrictedRoute path="/user-roles" component={UserRoles} />;
        case '/cost-management':
          return <RestrictedRoute path="/cost-management" component={CostManagement} />;
        case '/home':
          return <RestrictedRoute path="/home" component={Home} />;
        case '/activity-logs':
          return <RestrictedRoute path="/activity-logs" component={ActivityLogs} />;
        case '/complaint':
          return <RestrictedRoute path="/complaint" component={Complain} />;
        case '/user-details':
          return <RestrictedRoute path="/user-details" component={UserDetails} />;
        case '/category-management':
          return <RestrictedRoute path="/category-management" component={CategoryManagement} />;
      }
    });
  const home = menu && menu.find(i => i.link === '/home');
  if (home) {
    permissionsRoutes.push(<RestrictedRoute path="/view-all-leads" component={ViewAllLeads} />);
    permissionsRoutes.push(<RestrictedRoute path="/view-all-leads-by-location" component={ViewAllLeadsByLocation} />);
    permissionsRoutes.push(<RestrictedRoute path="/view-leads-details/:catid" component={MyJobs} />);
    permissionsRoutes.push(<RestrictedRoute path="/view-posted-or-quoted-leads" component={MyJobs} />);
    permissionsRoutes.push(<RestrictedRoute path="/jobs-details" component={JobDetails} />);
    permissionsRoutes.push(<RestrictedRoute path="/business-details/:id" component={BusinessPublicProfile} />);
    permissionsRoutes.push(<RestrictedRoute path="/user-search" component={UserSearch} />);
    permissionsRoutes.push(<RestrictedRoute path="/lead-search" component={LeadSearch} />);
    permissionsRoutes.push(<RestrictedRoute path="/view-leads-details-by-location/:location" component={MyJobs} />);
    permissionsRoutes.push(<RestrictedRoute path="/view-leads-details-by-month/:month" component={MyJobs} />);
    permissionsRoutes.push(<RestrictedRoute path="/view-user-details-by-month/:urlmonth" component={Home} />);
    permissionsRoutes.push(<RestrictedRoute path="/view-user-details-by-index/:index1/:index2" component={Home} />);
    permissionsRoutes.push(<RestrictedRoute path="/user-details" component={UserDetails} />);
    permissionsRoutes.push(<RestrictedRoute path="/category-management-edit/:catId" component={UpdateCategory} />);
  }

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/signin'} />;
  } else if (
    authUser &&
    (location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/forgot-password')
  ) {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        {permissionsRoutes &&
          permissionsRoutes.length &&
          permissionsRoutes.map(item => {
            return item;
          })}
        <Route path="/signin" component={Login} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path={`/reset-password`} component={CreateResetPassword} />
        <Route path={`/settings`} component={Settings} />

        {/*<Route path="/signup" component={Register}/>*/}
        <Route component={Error404} />
      </Switch>
      <NotificationContainer />
    </React.Fragment>
  );
};

export default Routes;
