import React, { useEffect, useState } from 'react';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import sweetAlerts from '../../../../../src/appmakers/components/SweetAlertHelper';
import ApmTable from '../../../../appmakers/components/ApmTable';
import { Filters } from './filters';
import moment from 'moment';

export const ActivityLogs = () => {
  const [loading, setLoading] = useState(true);
  const [activityLogs, setActivityLogs] = useState({
    params: {
      page: 0,
      perPage: 5,
      total: 0,
      user: null,
      module: null,
      action: null,
      startDate: moment()
        .startOf('day')
        .toString(),
      endDate: moment()
        .endOf('day')
        .toString(),
    },
    data: [],
  });

  const getActivityLogs = async () => {
    setLoading(true);
    await http
      .get(`${rootUrl}/activity-logs`, { params: activityLogs.params })
      .then(res => {
        setActivityLogs({
          ...activityLogs,
          params: { ...activityLogs.params, total: res.data.total },
          data: res.data.data,
        });
      })
      .catch(error => {
        // NotificationManager.error('Error while fetching data', 'Error')
        sweetAlerts('Error while fetching data', 'Error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    { title: 'User Email', field: 'user.email' },
    { title: 'Module', field: 'module' },
    { title: 'Action', field: 'action' },
    { title: 'Comment', field: 'comment' },
    { title: 'Activity Date Time', field: 'createdAt' },
  ];
  const paramsHandler = (key, value) => {
    setActivityLogs({
      ...activityLogs,
      params: { ...activityLogs.params, [key]: value },
    });
  };

  useEffect(() => {
    getActivityLogs();
  }, [
    activityLogs.params.page,
    activityLogs.params.perPage,
    activityLogs.params.user,
    activityLogs.params.action,
    activityLogs.params.module,
    activityLogs.params.startDate,
    activityLogs.params.endDate,
  ]);

  return (
    <div>
      <Filters setParams={setActivityLogs} activityLogs={activityLogs} />
      {!loading && (
        <ApmTable
          columns={columns}
          data={activityLogs.data}
          title="Activity Logs"
          showActions={false}
          params={activityLogs.params}
          paramsHandler={paramsHandler}
        />
      )}
    </div>
  );
};
