import React, { useEffect, useState } from 'react';
import ApmExpendableCard from './components/ApmExpendableCard';
import { http } from "../../../../appmakers/services/http-service";
import { rootUrl } from "../../../../api-handling/rootUrl";
import { CircularProgress, TextField, Grid } from "@material-ui/core";
import CmtCard from "@coremat/CmtCard";
import SearchIcon from '@material-ui/icons/Search';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardExpendableContent from '@coremat/CmtCardExpendableContent';
import { Autocomplete } from "@material-ui/lab";
import Radium, { StyleRoot } from 'radium';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Typography } from '@material-ui/core';
import { getOffSet } from "../../../../helpers/dateHelper"

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '16px',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const MyJobs = () => {
    const locations = useLocation();

    let [Alljobs, setAllJobs] = useState([]);
    let [jobs, setJobs] = useState([]);
    let [loading, setLoading] = useState(false);
    let [allProfession, setAllProfession] = useState([]);
    let [searchProfession, setSearchProfession] = useState('');
    let [searchStatus, setSearchStatus] = useState('');
    let [searchAll, setSearchAll] = useState('');
    let [perPage, setPerPage] = useState(10);
    let [moreToLoad, setMoreToLoad] = useState(true);

    const classes = useStyles();
    useEffect(() => {
        getProfessions();
        setSearchProfession('')
        setSearchStatus('')
        getJobs();
    }, [])
    useEffect(() => {
        getJobs();
    }, [perPage])
    let getProfessions = async () => {
        await http
            .get(`${rootUrl}/get-all-profession`)
            .then(res => {
                setAllProfession(res.data.data);
            })
            .catch(error => {
                console.log('Error: ', error);
            })
            .then(res => {
            });
    };
    let filterObj = (value) => {
        var smallvalue = value.toLowerCase();
        if (smallvalue.includes('ope') || smallvalue.includes('for') || smallvalue.includes('quo')) {
            var status = 'PENDING';
        } else if (smallvalue.includes('clo')) {
            var status = 'CLOSED';
        } else {
            var status = value;
        }
        const newJobs = Alljobs.filter(
            Alljobs => Alljobs.category.name.toLowerCase().includes(value) || Alljobs.status.includes(status) || moment(Alljobs.postedDate).format('MMM DD, YYYY').toLowerCase().includes(value) || Alljobs.quoteLimit == value || Alljobs.noOfQuotes == value
        );
        console.log(newJobs);
        setJobs(newJobs);
    };
    let handleSearchChange = (value) => {
        if (value.length > 0) {
            //getJobs('3',value)
            filterObj(value)
        } else if (value.length == 0) {
            getJobs();
        }
    }

    // let handleSearchChangeBack = (value) =>{
    //     if(value.length > 2){
    //         getJobs('3',value)
    //     }else if(value.length == 0){
    //         getJobs();
    //     }        
    // }
    let { catid, location, month } = useParams();
    let urlElements = window.location.href.split('/')
    let urlElelement = (urlElements[3])

    function debounce(func, timeout = 700) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const processChange = debounce((data, level) => getJobs(data, level));

    //console.log(urlElelement)
    const getJobs = (data, level) => {
        //debugger;
        let email = JSON.parse(localStorage.getItem('adminUser')).email;
        if (data == 1) {
            let id = level ? level._id : level;
            var params = { email, searchStatus, "searchProfession": id, searchAll }
            setSearchProfession(level ? level._id : level);
        }
        else if (data == 2) {
            var params = { email, "searchStatus": level, searchProfession, searchAll }
            setSearchStatus(level);
        }
        else if (data == 3) {
            var params = { email, searchStatus, searchProfession, "searchAll": level }
            setSearchAll(level)
        }
        else {
            const el1 = document.querySelector('#myjob.service')
            const el = document.querySelector('#standard-basic')

            var params = { email, "searchStatus": el1, "searchProfession": el }

        }


        console.log(params);
        if (urlElelement == "view-leads-details") {
            if (loading === true) return;
            setLoading(true)
            http.post(`${rootUrl}/get-jobs-by-categoryid`, {
                "id": catid,
                'searchParam': params,
                'perPage': perPage,
            }).then((res) => {
                console.log(res.data.data);
                setJobs(res.data.data);
                setAllJobs(res.data.data);
                if (jobs.length == res.data.data.length || res.data.data.length == 0) {
                    setMoreToLoad(false)
                }

                setLoading(false)
            }).then((error) => {
                console.log('error', error);
                setLoading(false)
            })
        } else if (urlElelement == "view-leads-details-by-location") {
            if (loading === true) return;
            setLoading(true)
            http.post(`${rootUrl}/get-jobs-by-location`, {
                "location": location,
                'searchParam': params,
                'perPage': perPage,
            }).then((res) => {
                console.log(res.data.data);
                setJobs(res.data.data);
                setAllJobs(res.data.data);
                setLoading(false)
                if (jobs.length == res.data.data.length || res.data.data.length == 0) {
                    setMoreToLoad(false)
                }
            }).then((error) => {
                console.log('error', error);
                setLoading(false)
            })
        } else if (urlElelement == "view-leads-details-by-month") {
            const utcOffset = getOffSet();
            const firstdate = moment().month(month - 1).startOf('month').utcOffset(utcOffset);
            const lastdate = moment().month(month - 1).endOf('month').utcOffset(utcOffset);
            debugger
            //    alert(firstdate)
            //    alert(lastdate)
            if (loading === true) return;
            setLoading(true)
            http.post(`${rootUrl}/get-jobs-by-Date`, {
                "firstdate": firstdate,
                "lastdate": lastdate,
                'searchParam': params,
                'perPage': perPage,
            }).then((res) => {
                console.log(res.data.data);
                setJobs(res.data.data);
                setAllJobs(res.data.data);
                if (jobs.length == res.data.data.length || res.data.data.length == 0) {
                    setMoreToLoad(false)
                }
                setLoading(false)
            }).then((error) => {
                console.log('error', error);
                setLoading(false)
            })
        } else if (urlElelement == "view-posted-or-quoted-leads") {
            //const items = locations.state.jobs;
            console.log(locations.state.jobs);
            setJobs(locations.state.jobs);
            setAllJobs(locations.state.jobs);
            setLoading(false)
        }
    }

    const allStatus = [
        'I need more quotes', 'PENDING', 'CLOSED'
    ]

    const style = {

        // Adding media querry..
        '@media (min-width: 800px)': { width: '100%', padding: '20px 0px 0px 0px', marginLeft: 'auto', marginRight: '0' },
        '@media (max-width: 500px)': { padding: '10px', marginLeft: 'auto', marginRight: '0' },
    };
    const mainContanerStyle = {
        '@media (min-width: 800px)': { padding: '0px 100px' }
    }
    const Swidth = window.innerWidth;
    return (
        <StyleRoot>
            <div style={mainContanerStyle}>

                <div
                    style={style}
                >

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5} sm={12}>
                            <Paper component="form" className={classes.root}>

                                <InputBase
                                    className={classes.input}
                                    placeholder="Search"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={e => {
                                        //handleSearchChange(e.target.value);
                                        processChange('3', e.target.value)

                                    }}
                                />
                                <IconButton className={classes.iconButton} aria-label="search">
                                    <SearchIcon />
                                </IconButton>

                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>
                            {/*<Paper component="form" className={classes.root}>
                            
                            <InputBase
                                className={classes.input}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={e => {
                                    handleSearchChangeBack(e.target.value);
                                }}
                            />
                            <IconButton className={classes.iconButton} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                           
                        </Paper> */}
                        </Grid>

                        <Grid item xs={12} md={3} sm={12}>
                            <Autocomplete
                                className="mt-3"
                                id="myjob.service"
                                name="services"
                                options={allProfession}
                                getOptionLabel={option => option.name}
                                onChange={(event, value) => getJobs('1', value)}
                                renderInput={params => <TextField {...params}
                                    id="standard-basic" label="Filter Category" size="medium"
                                />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>

                            <Autocomplete
                                className="mt-3"
                                id="myjob.status"
                                options={allStatus}
                                getOptionLabel={option => option}

                                onChange={(event, value) => getJobs('2', value)}
                                renderInput={params => <TextField {...params}
                                    id="standard-basic1" label="Filter Status" size="medium"
                                />
                                }
                            />
                        </Grid>

                    </Grid>

                </div>

                <div style={{ marginTop: '5vh' }}>
                    {/* {jobs &&
                jobs.map((job, index) => {
                    return (
                        <div style={{marginBottom: '1vh'}} key={index}>
                            <ApmExpendableCard getJobs={getJobs} job={job}></ApmExpendableCard>
                        </div>
                    );
                })} */}

                    <InfiniteScroll
                        dataLength={jobs?.length ? jobs.length : 0}
                        next={() => {
                            setPerPage(perPage + 10);
                        }}
                        hasMore={true}
                        style={{ overflowX: 'hidden', marginTop: '8px' }}
                        loader={
                            moreToLoad ? (
                                <Typography variant="h5" className="text-center">
                                    Loading...
                                </Typography>
                            ) : (
                                ''
                            )
                        }>
                        {jobs?.map((job, index) => {
                            return (
                                <div className="mt-2" key={index}>
                                    <ApmExpendableCard getJobs={getJobs} job={job}></ApmExpendableCard>
                                </div>
                            );
                        })}
                    </InfiniteScroll>
                </div>
                {jobs.length == 0 && loading == false && <div style={{ marginBottom: '1vh', textAlign: 'center' }}>
                    <CmtCard>
                        <CmtCardHeader
                            title={<h4 className="font-bold">You Have Not Posted Any Job</h4>}
                        >
                        </CmtCardHeader>

                    </CmtCard>
                </div>}
                {
                    loading &&
                    <div style={{
                        position: 'fixed',
                        zIndex: 9999,
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    }}>
                        <CircularProgress class="position-absolute" />
                    </div>
                }
            </div>
        </StyleRoot>
    );
};

export default MyJobs;
