import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import Radium, { StyleRoot } from 'radium';
import { useHistory } from 'react-router';

const userStyle = {
  '@media (max-width: 600px)': {
    boxShadow: '5px 7px 19px grey',
    width: '357px',
    backgroundColor: 'white',
    margin: '0px 0px 0px -25px',
    float: 'left',
    padding: '20px',
  },
  '@media (min-width: 601px)': {
    boxShadow: '5px 7px 19px grey',
    width: '357px',
    backgroundColor: 'white',
    margin: '0px 10px',
    float: 'left',
  },
};
export const UserPreference = () => {
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl();
  };

  const handleClck = index => {
    history.push('/view-user-details-by-index/' + msgnumeric + '/' + index);
  };
  const con = event => {
    const month = new Date().getMonth();
    if (event.target.value === 1) {
      getCount(1);
      setMsg("Today's Registered User");
      setMsgnumeric(1);
    }
    if (event.target.value === 2) {
      getCount(2);
      setMsg('This Month Registered User');
      setMsgnumeric(2);
    }
    if (event.target.value === 3) {
      getCount(3);
      setMsg('This Yesr Registered User');
      setMsgnumeric(3);
    }
    handleClose();
  };

  const getCount = val => {
    http
      .get(`${rootUrl}/get-daily-summary`)
      .then(res => {
        let result = '';
        if (val == 2) {
          result = res.data.data[0].users_count_month;
        } else if (val == 3) {
          result = res.data.data[0].users_count_year;
        } else {
          result = res.data.data[0].users_count;
        }
        console.log('alluserresult', result);
        const business_user = result.filter(user => {
          return user._id === 'Business User';
        });

        const buser = business_user[0] ? business_user[0].Count : 0;

        const normmal_user = result.filter(user => {
          return user._id === 'Customer User';
        });

        const nuser = normmal_user[0] ? normmal_user[0].Count : 0;

        setNCount(nuser);
        setCount(buser);
      })
      .catch(e => console.log(e));
  };
  useEffect(() => {
    getCount(1);
  }, []);
  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  const [count, setCount] = useState(0);
  const [n_count, setNCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(0);
  const [msg, setMsg] = useState("Today's Registered User");
  const [msgnumeric, setMsgnumeric] = useState('1');
  let history = useHistory();
  const open = Boolean(anchorEl);

  return (
    <StyleRoot>
      <div>
        <Card style={userStyle}>
          <CardContent>
            <Button
              aria-controls="fade-menu"
              aria-haspopup="true"
              style={{ textTransform: 'capitalize', fontSize: '14px', backgroundColor: '#686cfc', color: '#f2f3f7' }}
              onClick={handleClick}>
              {msg}
            </Button>
            <Menu
              id="fade-menu"
              anchorEl={anchorEl}
              keepMounted
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}>
              <MenuItem onClick={con} value="1">
                Today
              </MenuItem>
              <MenuItem onClick={con} value="2">
                This Month
              </MenuItem>
              <MenuItem onClick={con} value="3">
                This Year
              </MenuItem>
            </Menu>

            <Divider />
          </CardContent>

          <CardContent>
            <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
              <h7 style={{ color: 'black' }} onClick={() => handleClck(1)}>
                {' '}
                Business user : {count}
              </h7>
              <Button
                variant="contained"
                color="primary"
                endIcon={<VisibilityIcon />}
                style={{
                  float: 'right',
                  borderRadius: '20px',
                  height: '25px',
                  textTransform: 'capitalize',
                }}
                onClick={() => handleClck(1)}
              >
                View
              </Button>
            </Typography>
          </CardContent>
          <CardContent>
            <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
              <h7 style={{ color: 'black' }} >
                {' '}
                Customer user : {n_count}
              </h7>
              <Button
                variant="contained"
                color="primary"
                endIcon={<VisibilityIcon />}
                style={{
                  float: 'right',
                  borderRadius: '20px',
                  height: '25px',
                  textTransform: 'capitalize',
                }}
                onClick={() => handleClck(2)}
              >
                View
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </div>
    </StyleRoot>
  );
};
export default UserPreference;
