import React, { useEffect, useState } from 'react';
import UserPreference from './userpreferences';
import LeadPreferences from './leadpreferences';
import LeadPreferencesLocation from './leadpreferences-location';
import MonthsStatisticsLead from './monthlyStatisticsLead';
import MonthsStatisticsUser from './monthlyStatisticsUser';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import WelcomeMessage from './welcomemessage';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { getProfession } from '../../../../redux/actions/CostManagementAction';
import { fetchStart, fetchSuccess } from '../../../../redux/actions/Common';

import { rootUrl } from '../../../../api-handling/rootUrl';
import { http } from '../../../../appmakers/services/http-service';

const useStyles = makeStyles(() => ({
  welcomeDisplay: {
    display: 'flex',
    ['@media (max-width:741px)']: {
      display: 'block'
    }
  },
  searchDivPosition: {
    ['@media (max-width:741px)']: {
      position: 'absolute !important'
    }
  },
  rootWidth: {
    width: '80%',
    ['@media (max-width:550px)']: {
      width: '95%'
    }
  }
}))
export const Dashboard = () => {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfession());
  }, []);
  const refreshData = () => {
    dispatch(fetchStart());
    http
      .get(`${rootUrl}/update-dashboard-data`)
      .then(res => {
        dispatch(fetchStart());
        window.location.reload();
      })
  }
  return (
    <div className={`${classes.rootWidth}`} style={{ margin: '0px auto' }}>
      <div className={`${classes.welcomeDisplay} justify-content-between`}>
        <WelcomeMessage />
        <div className={`${classes.searchDivPosition}`}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<SearchIcon />}
            style={{ margin: '10px 7px', float: 'right', borderRadius: '20px', height: '25px' }}
            onClick={() => {
              history.push('/user-search');
            }}
            className={'text-capitalize'}>
            User Search
          </Button>
        </div>

        <div>
          <Button
            variant="contained"
            color="primary"
            endIcon={<SearchIcon />}
            style={{ margin: '10px 7px', float: 'right', borderRadius: '20px', height: '25px' }}
            onClick={() => {
              history.push('/lead-search');
            }}
            className={'text-capitalize'}>
            Lead Search
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            endIcon={<RotateRightIcon />}
            style={{ margin: '10px 7px', float: 'right', borderRadius: '20px', height: '25px' }}
            onClick={refreshData}
            className={'text-capitalize'}>
            Refresh Data
          </Button>
        </div>
      </div>

      <Divider style={{ marginBottom: '20px' }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4}>
          <UserPreference />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <LeadPreferences />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <LeadPreferencesLocation />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={5} md={3} lg={2} xl={2}>
          <MonthsStatisticsLead />
        </Grid>
        <Grid item xs={6} sm={5} md={3} lg={2} xl={2}>
          <MonthsStatisticsUser />
        </Grid>
      </Grid>
    </div>
  );
};
