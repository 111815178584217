import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { UserDetails } from './UserDeatailsNew';
import Grid from '@material-ui/core/Grid';
import Input from '@mui/material/Input';
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import sweetAlerts from 'appmakers/components/SweetAlertHelper';
import _ from 'lodash';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@material-ui/styles';
import Visibility from '@material-ui/icons/Visibility';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ApmTable from '../../../../appmakers/components/ApmTableUserManagement';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Swal from 'sweetalert2';
import { MySwal } from 'helpers/Swal';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeParams,
  deleteUser,
  deleteMultipleUser,
  getOtherUsers,
  getAllUsersByDate,
  getAllUsersByDateType,
  getAdminUsers,
  updateUser,
  toggleModal,
  selectUser,
  addUser,
} from '../../../../redux/actions/UserManagementActions';
import { useParams } from 'react-router';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { UpdateAdminRoles } from '../admins/UpdateRoles';
import moment from 'moment';
import SearchBox from '@jumbo/components/AppLayout/VerticalLayouts/ModernSidebar/ActionSideBar/Search/SearchBox';
import { getSearchedUsers, UpdateUserStatus } from '../../../../redux/actions/UserManagementActions';
import { getProfession } from '../../../../redux/actions/CostManagementAction';
import { ToSeoUrl } from '../../../Pages/Common/commonFunctions'
import { locationsJSON } from '../../../../constants/DataBasedOnUrl'
import { getOffSet } from "../../../../helpers/dateHelper"
// import searchLocations from '../../../../api-handling/fetchLocations';

const useStyles = makeStyles(theme => ({
  jobForm: {
    marginTop: '10rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
}));

export const Home = ({ isAdmin }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const utcOffset = getOffSet();
  let { users, params } = useSelector(({ UserManagementReducer }) => UserManagementReducer);
  const { professions } = useSelector(({ CostManagementReducer }) => CostManagementReducer);
  console.log('professions=', professions);
  const [userType, setUserType] = useState(null);

  let { loading } = useSelector(({ common }) => common);
  let urlElements = window.location.href.split('/');
  let urlElement = urlElements[3];
  let { urlmonth, index1, index2 } = useParams();
  const [firstDate, setFirstDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [locations, setLocations] = useState(locationsJSON);

  const [nameError, setNameError] = useState({
    error: false,
    helperText: '',
  });

  useEffect(() => {
    if (professions.length == 0) {
      dispatch(getProfession());
    }
  }, []);
  var firstdate = new Date(new Date().getFullYear(), Number(urlmonth - 1), 1);
  var lastdate = new Date(new Date().getFullYear(), Number(urlmonth - 1) + 1, 0);
  const [open, setOpen] = useState(false);
  const handleOpen = rowData => {
    dispatch(selectUser(rowData));
    history.push({
      pathname: '/user-details',
      state: rowData, // your data array of objects
    });
  };
  const [searched, setSearched] = useState('');
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (nameError.error) {
      MySwal.fire({
        icon: 'info',
        html: `${nameError.helperText}`,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: 'Ok',
        confirmButtonAriaLabel: 'ok',
      })
    }
  }, [nameError.error])
  useEffect(() => {
    const utcOffset = getOffSet();
    const nowUTC = moment.utc();
    const now = nowUTC.clone().utcOffset(utcOffset);

    const startOfDay = now.clone().startOf('day');
    const endOfDay = now.clone().endOf('day');

    const startOfMonth = now.clone().startOf('month');
    const EndOfMonth = now.clone().endOf('month');

    const startOfYear = now.clone().startOf('year');
    const endOfYear = now.clone().endOf('year');

    // const date = getTwoDigitNumber(new Date().getDate());
    // const month = getTwoDigitNumber(new Date().getMonth() + 1);
    // const fullYear = new Date().getFullYear();
    // const fullDateWithTime = `${fullYear}-${month}-${date} 00:00:00`;
    // const now = moment(fullDateWithTime).add(utcOffset, 'minutes');



    if (urlElement == 'view-user-details-by-month') {
      const nowUTC = moment.utc();
      const now = nowUTC.clone().utcOffset(utcOffset);
      const startOfMonth = now.clone().month(urlmonth - 1).startOf('month');
      const EndOfMonth = now.clone().month(urlmonth - 1).endOf('month');

      setFirstDate(startOfMonth.toDate());
      setLastDate(EndOfMonth.toDate());
    }
    if (urlElement == 'view-user-details-by-index') {
      if (index1 == 1 && index2 == 1) {
        setFirstDate(startOfDay.toDate());
        setLastDate(endOfDay.toDate());
        setUserType('Business User');
      }
      if (index1 == 1 && index2 == 2) {
        setFirstDate(startOfDay.toDate());
        setLastDate(endOfDay.toDate());
        setUserType('Customer User');
      }
      if (index1 == 2 && index2 == 1) {
        setFirstDate(startOfMonth.toDate());
        setLastDate(EndOfMonth.toDate());
        setUserType('Business User');
      }
      if (index1 == 2 && index2 == 2) {
        setFirstDate(startOfMonth.toDate());
        setLastDate(EndOfMonth.toDate());
        setUserType('Customer User');
      }
      if (index1 == 3 && index2 == 1) {
        setFirstDate(startOfYear.toDate());
        setLastDate(endOfYear.toDate());
        setUserType('Business User');
      }
      if (index1 == 3 && index2 == 2) {
        setFirstDate(startOfYear.toDate());
        setLastDate(endOfYear.toDate());
        setUserType('Customer User');
      }
    }
  }, []);

  useEffect(() => {
    if (firstDate && lastDate) {
      if (urlElement == 'view-user-details-by-month') {
        dispatch(getAllUsersByDate(firstDate, lastDate, params));
      } else if (urlElement == 'view-user-details-by-index' && userType) {
        dispatch(getAllUsersByDateType(firstDate, lastDate, userType, params));
      }
    }
  }, [firstDate, lastDate, userType, params.page, params.perPage, trigger]);


  const delaygetSearchedUsers = React.useCallback(
    _.debounce((params, searched) => {
      dispatch(getSearchedUsers(params, searched))
    }, 1500),
    [],
  );


  useEffect(() => {
    if (urlElement !== 'view-user-details-by-month' && urlElement !== 'view-user-details-by-index') {
      console.log('inside');
      if (isAdmin) {
        dispatch(getAdminUsers(params));
      } else {
        delaygetSearchedUsers(params, searched);
        // dispatch(getOtherUsers(params));
      }
    }
  }, [params.page, params.perPage, trigger, searched]);

  // useEffect(() => {
  //   const controller = new AbortController();
  //   http
  //     .get(
  //       `${rootUrl}/search-users-by-all?searchText=${searched}`,
  //       { params },
  //       {
  //         signal: controller.signal,
  //       },
  //     )
  //     .then(res => {
  //       console.log('result', res);
  //       dispatch(getAllUsersSuccess(res.data));
  //     })
  //     .catch(err => console.log('err', err));

  //   return () => controller.abort();
  // }, []);
  const handleLocationChange = async (value) => {
    // if (value.length > 2) {
    //   await searchLocations(value).then(res => {
    //     if (res.length) {
    //       setLocations(res);
    //     }
    //   });
    // } else {
    //   setLocations([]);
    // }
  };
  // useEffect(() => {
  //   debugger
  //   console.log(locations)
  // }, [locations])
  const addUserHandler = user => {
    if (user.businessName != '')
      user.businessUrl = ToSeoUrl(user.businessName)
    dispatch(addUser(user));
    setTrigger(prev => !prev);
  };

  const updateUserHandler = user => {
    dispatch(updateUser(user));
    setTrigger(prev => !prev);
  };

  const paramsHandler = (key, value) => {
    dispatch(changeParams(key, value));
  };

  const deleteUserHandler = user => {
    dispatch(deleteUser(user._id));
    setTimeout(() => {
      setTrigger(prev => !prev);
    }, 2000);
  };
  const UserStatusHandler = user => {
    dispatch(UpdateUserStatus(user._id));
    setTimeout(() => {
      setTrigger(prev => !prev);
    }, 2000);
  };
  const getProfessions = () => {
    return professions
  }
  const deleteUserHandlernew = user => {
    let id = user;
    const userIds = user.map(usr => usr._id);
    MySwal.fire({
      icon: 'info',
      html: '<p>Are you sure ?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Yes',
      confirmButtonAriaLabel: ' yes',
      cancelButtonText: 'No',
      cancelButtonAriaLabel: 'no',
    }).then(result => {
      if (result.value) {
        dispatch(deleteMultipleUser(userIds));
        setTimeout(() => {
          setTrigger(prev => !prev);
        }, 2000);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        sweetAlerts('Cancelled', 'error');
      }
    });
  };

  const columns = [
    {
      title: 'Name', field: 'userName', cellStyle: {
        whiteSpace: 'nowrap'
      }
    },
    {
      title: 'Email', field: 'email',
      editComponent: (props) => (
        <TextField
          type="text"
          value={props.value ? props.value : ''}
          onChange={e => props.onChange(e.target.value)}
        />
      ), cellStyle: {
        whiteSpace: 'nowrap'
      }
    },
    {
      title: 'Mobile', field: 'mobile', cellStyle: {
        whiteSpace: 'nowrap'
      }
    },
    {
      title: 'Registration', field: 'registrationDate',
      render: (rowData) => {
        return (<div>{moment(rowData.registrationDate).utcOffset(utcOffset).format('Do MMMM YYYY')}</div>)
      },
      editComponent: (props) => {
        return (<TextField
          id="date"
          type="date"
          value={props.rowData.registrationDate ? moment(props.rowData.registrationDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
          onChange={e => props.onChange(e.target.value)}
        />)
      }, cellStyle: {
        whiteSpace: 'nowrap'
      }
    }
  ];
  let actions = [
    {
      icon: PersonOffIcon,
      tooltip: 'Inactive',
      onClick: (event, rowData) => {
        deleteUserHandlernew(rowData);
      },
    },
  ];
  if (!isAdmin) {
    columns.push({
      title: 'User Type', field: 'userType',
      editComponent: (props) => {
        return (
          <FormControl variant="standard" >
            <Select
              size="small"
              value={props.rowData.userType}
              onChange={(e) => props.onChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {['Business User', 'Customer User'].map(option => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }, cellStyle: {
        whiteSpace: 'nowrap'
      }
    });
    columns.push({
      title: 'Business Name', field: 'businessName',
      editComponent: (props) => {
        if (props.rowData.userType == 'Business User') {
          return (
            <Input placeholder="Business Name"
              value={props.rowData.businessName}
              onChange={(e) => props.onChange(e.target.value)}
            />
          );
        } else {
          return <></>
        }
      }, cellStyle: {
        whiteSpace: 'nowrap'
      }
    });
    columns.push({
      title: 'Business Number', field: 'businessABN',
      editComponent: (props) => {
        if (props.rowData.userType == 'Business User') {
          return (
            <Input placeholder="Business Number"
              value={props.rowData.businessABN}
              error={nameError.error}
              helperText={nameError.helperText}
              onChange={(e) => props.onChange(e.target.value)}
            />
          );
        } else {
          return <></>
        }
      },
      cellStyle: {
        whiteSpace: 'nowrap'
      }
    });
    columns.push({
      title: 'Business Profession', field: 'professions',
      render: rowData => {
        let catName = rowData.professions.map((item) => { return item.name })
        return catName.join();
      },
      editComponent: (props) => {
        if (props.rowData.userType == 'Business User') {
          return (
            <Autocomplete
              multiple
              value={props.value}
              onChange={(e, nv) => { props.onChange(nv) }}
              id="combo-box-demo"
              options={professions}
              defaultValue={props.rowData.professions}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Professions" variant="outlined" />}
            />
          );
        } else {
          return <></>
        }
      }
    });
    columns.push({
      title: 'Address', field: 'address',
      editComponent: (props) => {
        if (props.rowData.userType == 'Business User') {
          return (
            // <Input placeholder="Address"
            //   value={props.rowData.address}
            //   error={nameError.error}
            //   helperText={nameError.helperText}
            //   onChange={(e) => props.onChange(e.target.value)}
            // />
            <Autocomplete
              id="combo-box-demo"
              options={locations}
              Value={props.value}
              //value={address}
              // getOptionLabel={option => (option ? option.locality : '')}  //to avoied duplicate locality
              // onChange={(event, value) => {
              //   setAddress(value);
              // }}
              getOptionLabel={option => (option ? option.postcode + ', ' + option.locality + ', ' + option.territory : '')}
              //onChange={(event, value) => setAddress(value.postcode + ', ' + value.locality + ', ' + value.territory)}
              onChange={(e, nv) => {
                //debugger
                props.onChange(nv.postcode + ', ' + nv.locality + ', ' + nv.territory)
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  onChange={e => {
                    handleLocationChange(e.target.value);
                  }}
                  placeholder="Type 3 letter to search locations.."
                  label="Where are you based on?"
                  //size="small"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  style={{ width: 300 }}
                //className={classes.textFieldRoot}
                />
              )}
            />
          );
        } else {
          return <></>
        }
      },
      cellStyle: {
        whiteSpace: 'nowrap'
      }
    });
    columns.push({
      title: 'Auto Quote', field: 'isAutoquote', type: 'boolean',
      render: rowData => rowData.isAutoquote == false ? 'Off' : 'On',
      editComponent: (props) => {
        if (props.rowData.userType == 'Business User') {
          return (
            <input
              type="checkbox"
              checked={props.value}
              onChange={(e) => props.onChange(e.target.checked)}
            />
          );
        } else {
          return <></>
        }
      }, cellStyle: {
        whiteSpace: 'nowrap'
      }
    });
    columns.push({
      title: 'Is Spam', field: 'isSpam', type: 'boolean',
      render: rowData => rowData.isSpam == false ? 'No' : 'Yes',
      editComponent: (props) => {
        return (
          <input
            type="checkbox"
            checked={props.value}
            onChange={(e) => props.onChange(e.target.checked)}
          />
        )
      }, cellStyle: {
        whiteSpace: 'nowrap'
      }
    });
    columns.push({
      title: 'Status',
      field: 'status',
      editComponent: (props) => {
        return (
          <FormControl variant="standard" >
            <Select
              size="small"
              value={props.rowData.status || 'PENDING'}
              onChange={(e) => props.onChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {['PENDING', 'APPROVE', 'DISABLE'].map(option => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
      cellStyle: {
        whiteSpace: 'nowrap'
      }
    });
    columns.push({
      title: "View",
      field: "isSpam",
      editable: false,
      render: (rowData) =>
        rowData && (
          <Visibility
            color="secondary"
            onClick={() => handleOpen(rowData)}
          />
        ),
      editComponent: (props) => {
        return <></>
      }, cellStyle: {
        whiteSpace: 'nowrap'
      }
    });
    columns.push({
      title: "User Status",
      field: "isActive",
      editable: false,
      render: (rowData) =>
        rowData.isActive == true ? (
          <PersonIcon
            color="secondary"
            onClick={() => UserStatusHandler(rowData)}
          />
        ) : (
          <PersonOffIcon
            color="secondary"
            onClick={() => UserStatusHandler(rowData)}
          />
        ),
      editComponent: (props) => {
        return <></>
      },
      cellStyle: {
        whiteSpace: 'nowrap'
      }
    });
  } else {
    actions = [
      {
        icon: SystemUpdateAltIcon,
        tooltip: 'Update Roles',
        onClick: (event, rowData) => {
          dispatch(selectUser(rowData));
          dispatch(toggleModal(true));
        },
      },
    ];
  }

  const requestSearch = value => {
    paramsHandler('page', 0);

    setSearched(value);
  };

  return (
    <div>
      <div>
        <UpdateAdminRoles />
      </div>
      <div>
        <SearchBox value={searched} onChange={requestSearch} />
      </div>
      {/* <UserDetails open={open} close={handleClose} /> */}
      {!isAdmin ? (
        <PageContainer>
          <Grid container spacing={3} className="justify-center">
            <Grid item md={12} sm={12} xs={12}>
              {!loading && locations?.length > 0 && professions?.length > 0 && (
                <ApmTable
                  NameError={setNameError}
                  columns={columns}
                  data={users}
                  title="All Users"
                  name="User Management"
                  params={params}
                  actions={actions}
                  addHandler={addUserHandler}
                  updateHandler={updateUserHandler}
                  paramsHandler={paramsHandler}
                  deleteHandler={deleteUserHandler}
                />
              )}
            </Grid>
          </Grid>
        </PageContainer>
      ) : (
        !loading && locations?.length > 0 && professions?.length > 0 && (
          <ApmTable
            NameError={setNameError}
            columns={columns}
            data={users}
            title="All Users"
            name="User Management"
            params={params}
            actions={actions}
            updateHandler={updateUserHandler}
            paramsHandler={paramsHandler}
            deleteHandler={deleteUserHandler}
          />
        )
      )}
      <div>
        <UpdateAdminRoles />
      </div>
    </div>
  );
};

const getTwoDigitNumber = number => {
  return number < 10 ? `0${number}` : number;
};
